<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Huesped - Ver</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form">
              <b-row>

                <b-col md="6">
                  <b-form-group label="Nombres :">
                    <b-form-input disabled type="text" v-model="guest.name"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Email :">
                    <b-form-input disabled type="text" v-model="guest.email"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Teléfono :">
                    <b-form-input disabled type="text" v-model="guest.email"></b-form-input>
                  </b-form-group>
                </b-col>
      
          
                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-link class="btn form-control btn-primary" :to="{ path: '/huesped/listar' }" append >REGRESAR</b-link>
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import LoadingComponent from './../pages/Loading'

export default {
  name: "guesteView",
  props: ["id_guest"],
  components:{
    vSelect,
    LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module:'Guest',
      guest: {
        id_guest: "",
        name: "1",
        email: "",
        phone: "",
      },
    };
  },
  mounted() {
    this.ViewGuest();
    
  },
  methods: {
    ViewGuest,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};


function ViewGuest() {
  let me = this;
  let id_guest = je.decrypt(me.id_guest);
  let url = me.url_base + "guest/view/" + id_guest;
  axios({
    method: "GET",
    url: url,
    headers: { token: me.token, module: me.module, role: 5,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.guest.name = response.data.result.name;
        me.guest.email = response.data.result.email;
        me.guest.phone = response.data.result.phone;
        me.guest.state = response.data.result.state;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

</script>
